<template>
  <div  class="content ma-1 mb-16 mt-4 px-0 px-md-16  mx-md-14" >
    <div class="text-h6 font-weight-bold mx-6">购物车</div>
    <div class="text-body-1 mb-6 mt-0 mx-6">共{{selected_cart.length}}件单品</div>
    <v-card class="main-header" till flat>
      <v-row>
        <v-col class="pl-8" cols="5">
          商品信息
        </v-col>
        <v-col class="pl-8" cols="2" v-if="!$vuetify.breakpoint.mobile">
          规格
        </v-col>
        <v-col class="pl-8" cols="2" v-if="!$vuetify.breakpoint.mobile">
          数量
        </v-col>
        <v-col class="pl-8" cols="2" v-if="!$vuetify.breakpoint.mobile">
          单价
        </v-col>
        <v-col class="" cols="1" v-if="!$vuetify.breakpoint.mobile">
          
        </v-col>
      </v-row>
     </v-card>
     <v-card class="my-4 " till flat >
       <v-list-item-group
        multiple
        class="mt-8"
        active-class=""
        color="primary"
        v-model="selected_cart"
      >
        <v-list-item v-for="cart in cartlist" :key="cart.cart_id" :value="cart.cart_id">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>

            <v-row v-if="!$vuetify.breakpoint.mobile">
              <v-col class="pl-8" cols="5">
                <v-row>
                  <v-col class="" cols="3">
                    <v-img :src="domain + cart.picture_info.pic_cover_mid" max-height="200" max-width="200"></v-img>
                  </v-col>
                  <v-col class="" cols="9">
                    <div class="text-h7 font-weight-bold ">{{cart.brand_name}}</div>
                    <div class="text-h7  ">{{cart.goods_name}}</div>
                    
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="" cols="2">
                {{cart.sku_name}}
              </v-col>
              <v-col cols="2">
                <!--<v-select
                    :items="CartCount"
                    v-model="cart.num"
                    width="60px"
                    label=""
                    @click.stop
                  ></v-select>-->
                  
                    
                    <v-text-field
                      class="cartnum mx-2"
                      prepend-inner-icon="mdi-minus"
                      append-icon="mdi-plus"
                      v-model="cart.num"
                      :rules="[rules.required, rules.number]"
                      label=""
                      @click.stop
                      @click:append="cart.num++"
                      @click:prepend-inner="cart.num>1?cart.num--:1"
                    ></v-text-field>
                    
                 
              </v-col>
              <v-col class="pt-8" cols="2">
                {{cart.price}}
              </v-col>
              <v-col cols="1">
                <v-btn  icon small class="favorite-icon mt-6 mr-0" fab absolute  right @click.stop="removecart(cart.cart_id)" >
                  <v-icon size=20>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="$vuetify.breakpoint.mobile">
              <v-col class="" cols="4">
                <v-img :src="domain + cart.picture_info.pic_cover_mid" max-height="200" max-width="200"></v-img>
              </v-col>
              <v-col class="" cols="8">
                <div class="text-subtitle-2 font-weight-bold">{{cart.brand_name}}</div>
                <div class="text-subtitle-2  ">{{cart.goods_name}}</div>
                <div class="text-body-2  ">{{cart.sku_name}}</div>
                <div class="">
                  <v-row >
                    <v-col class="ma-0 pa-0" cols="7">
                      <v-text-field
                        class="cartnum ma-1 py-2"
                        prepend-inner-icon="mdi-minus"
                        append-icon="mdi-plus"
                        v-model="cart.num"
                        :rules="[rules.required, rules.number]"
                        label=""
                        @click.stop
                        @click:append="cart.num++"
                        @click:prepend-inner="cart.num>1?cart.num--:1"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-5" cols="5">
                      ￥{{cart.price}}
                    
                    </v-col>
                  </v-row>
                </div>
                <v-btn  icon small class="favorite-icon mt-2 mr-0" fab absolute top right @click.stop="removecart(cart.cart_id)" >
                  <v-icon size=20>mdi-close</v-icon>
                </v-btn>
                    
                
              </v-col>
            </v-row>

            </v-list-item-content>
            
          </template>
        </v-list-item>
       </v-list-item-group>
    </v-card>

    

    <v-divider></v-divider>
    
    <v-row class="my-4 px-6">
      <v-col class="pa-1" cols="12" offset-md="8" md="4">
        <div class="float-left">小计</div><div class="float-right">¥ {{sumamount}}</div>
      </v-col>
      <v-col class="pa-1" cols="12" offset-md="8" md=4>
        <div class="float-left">运费</div><div class="float-right">¥ 0</div>
      </v-col>
      <v-col class="pa-1 font-weight-bold" cols="12" offset-md="8" md=4>
        <div class="float-left">总计</div><div class="float-right">¥ {{sumamount}}</div>
      </v-col>
    </v-row>
    
    <v-divider></v-divider>

    <v-row class="my-4">
      <v-col class="" cols="12" offset-md="8" md=4>
        <v-btn  dark outlined  tile class="main-btn" @click="gosettle">结算</v-btn>
      </v-col>
    </v-row>


    <Likes />


  </div>
</template>

<script>

import goodsApi from '@/api/goods'
import { mapState } from 'vuex'
import Likes from '@/components/Likes';


export default {

  components: {
      Likes
  },

  data: () => ({
    //sumamount : 0,
    selected_cart:[],
    CartCount:[1,2,3,4,5,6,7,8,9],
    cartlist : [],
    rules: {
          required: value => !!value || '请输入购买数量.',
          number: value => {
            const pattern = /^[0-9]*$/
            return pattern.test(value) || '请输入数字.'
          },
        }
    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
    }),
    sumamount : function() {
      let sum = 0
      this.cartlist.forEach(cart => {
        if(this.selected_cart.indexOf(cart.cart_id)>=0) {
          sum += cart.price * cart.num
        }
        
      });
      return sum.toFixed(2)
    }
  },
  created() {
    
    this.getcarts()
    
  },
  methods: {
    getcarts() {
      goodsApi.cartlist().then(res => {
        this.cartlist = res.data.cart_list
        this.cartlist.forEach(item => {
          this.selected_cart.push(item.cart_id)
        })
      })
    },
    gosettle() {
      let selected = []
      this.cartlist.forEach(item => {
        if (this.selected_cart.indexOf(item.cart_id)>=0) {
          selected.push(item)
        }
      })
      if (selected.length>0) {
        this.$store.dispatch("order/set_pre_order_info", selected)
        this.$router.push("/order")
      } else {
        this.$message.warning("请选择商品！")
      }
      
      
    },
    removecart(id) {
      goodsApi.removecart({cart_id_array:id}).then(() => {
        this.getcarts()
      })
    }
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.main-header {
  background-color: black;
  color:#ffffff;
  
  width:100%;
}
.cartnum >>> input {
  text-align: center;
}
</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>